import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VueQrCodeComponent from 'vue-qrcode-component';
import { mainStore, userStore } from '@/store';
import UpdatePasswordUserComponent from '@/components/UpdatePasswordUserComponent.vue';
let UserProfile = class UserProfile extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.autenticacao2FA = false;
        this.headers = [
            { text: 'Data/Hora', value: 'created_at' },
            { text: 'Usuário', value: 'request_by_name' },
            { text: 'Operação', value: 'action.name' },
        ];
        this.items = [];
    }
    copyToClipboard() {
        navigator.clipboard.writeText(this.userProfile.otp_now.replace(' ', ''));
    }
    async refreshCode() {
        this.loading = true;
        await mainStore.getUserProfile();
        this.loading = false;
    }
    get userProfile() {
        this.autenticacao2FA = mainStore.userProfile.login_2fa_enabled;
        return mainStore.userProfile;
    }
    goToEdit() {
        this.$router.push('/main/profile/edit');
    }
    goToPassword() {
        this.$router.push('/main/profile/password');
    }
    async update2fa() {
        if (this.loading) {
            return;
        }
        if (!this.autenticacao2FA) {
            await userStore.updateUserLoginOtp();
            this.refreshCode();
            return;
        }
        this.$swal({
            title: `<span style="color: red">CUIDADO<span>`,
            text: 'Ao ativar a autenticação em dois fatores é necessário escanear o QRCODE desta tela',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await userStore.updateUserLoginOtp();
                this.refreshCode();
            }
            else {
                this.autenticacao2FA = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        if (!this.userProfile.is_aluno) {
            if (!this.userProfile.otp_uri) {
                await userStore.generateNewTOTP();
            }
            await mainStore.getUserProfile();
            this.items = await userStore.getLogOtp(this.userProfile.id);
        }
        this.loading = false;
    }
    atualizarSenha() {
        const form = this.$refs.dialogUpdatePassword;
        form.show(true);
    }
};
UserProfile = __decorate([
    Component({
        components: {
            VueQrCodeComponent,
            UpdatePasswordUserComponent
        },
    })
], UserProfile);
export default UserProfile;
