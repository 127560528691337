import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, } from 'vee-validate';
import { userStore, mainStore } from '@/store';
let UpdatePasswordUserComponent = class UpdatePasswordUserComponent extends Vue {
    constructor() {
        super(...arguments);
        this.password1 = '';
        this.password2 = '';
        this.loadingResetPassword = false;
        this.dialog = false;
    }
    async onConfirmResetPassword() {
        this.loadingResetPassword = true;
        // @ts-ignore
        const isValid1 = await this.$refs.resetParsswordObserver.validate();
        if (!isValid1) {
            this.loadingResetPassword = false;
            return;
        }
        const payload = {
            id: this.propUserId,
            new_password: this.password1
        };
        if (mainStore.userProfile.is_aluno) {
            await userStore.updateCurrentUserPassword(payload);
        }
        else {
            await userStore.updatePassword(payload);
        }
        this.loadingResetPassword = false;
        this.dialog = false;
    }
    async show(value) {
        this.dialog = value;
    }
};
__decorate([
    Prop({ type: Number, default: '' })
], UpdatePasswordUserComponent.prototype, "propUserId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UpdatePasswordUserComponent.prototype, "propDialogRedefinirSenha", void 0);
UpdatePasswordUserComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], UpdatePasswordUserComponent);
export default UpdatePasswordUserComponent;
